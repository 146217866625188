import Link from 'next/link';
import { MouseEventHandler } from 'react';

import Hamburguer from 'public/icons/hamburguer.svg';
import Headphone from 'public/icons/headphone.svg';
import Logo from 'public/icons/logo.svg';
import styles from 'styles/Layout/Navbar.module.css';

import Menu from './Menu';
import { MenuType } from '@/types';

type NavbarProps = {
  isOpen: boolean;
  close: any;
  toggle: MouseEventHandler<HTMLDivElement>;
  menus: MenuType[];
  setMenus: (m: MenuType[]) => void;
};

export default function Navbar({
  isOpen,
  close,
  toggle,
  menus = [],
  setMenus,
}: NavbarProps) {
  const toggleMenu = (menu: MenuType) => {
    const newMenus = [...menus];
    const index = menus.findIndex(el => el.id === menu.id);

    newMenus[index] = {
      ...menu,
      active: !menu.active,
    };

    setMenus(newMenus);
  };

  return (
    <div className={styles.navbar} onClick={close}>
      <div className={styles.logo__wrap}>
        <Link href="https://aluguefoco.com.br" legacyBehavior>
          <a>
            <Logo />
          </a>
        </Link>
      </div>
      <nav
        className={`${styles.nav} ${isOpen ? styles.open : ''}`}
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.nav__container}>
          {menus.map(({ id, text, link, target, submenus, active }) => {
            return (
              <div
                key={`menu_wrap_${id}`}
                className={styles.menu__wrap}
              // onMouseEnter={() =>
              //   toggleMenu({
              //     id,
              //     text,
              //     link,
              //     target,
              //     submenus,
              //     active: false,
              //   })
              // }
              >
                <Menu
                  key={`menu_${id}`}
                  text={text}
                  link={link}
                  target={target}
                  submenus={submenus}
                  onClick={() =>
                    toggleMenu({ id, text, link, target, submenus, active })
                  }
                />
                {submenus && submenus?.length > 0 && (
                  <div
                    key={`submenu_wrap_${id}`}
                    className={`${styles.sub_menus} ${active ? styles.sub_menus__open : ''
                      }`}
                    onMouseLeave={() =>
                      toggleMenu({
                        id,
                        text,
                        link,
                        target,
                        submenus,
                        active: true,
                      })
                    }
                  >
                    {submenus.map(({ id, text, link, target }) => (
                      <Menu
                        key={`submenu_${id}`}
                        text={text}
                        link={link}
                        target={target}
                      />
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </nav>
      <div className={styles.navbar__content}>
        <div className={styles.content__icons}>
          <div
            className={`${styles.icons__icon__wrap} ${styles.icons__icon__wrap__language}`}
          >
            <p className={styles.icon__text}>PT-BR</p>
          </div>
          <div className={styles.icons__icon__wrap}>
            <Link href="tel:40072109" legacyBehavior>
              <a>
                <Headphone />
                <p className={styles.icon__text}>4007.2109</p>
              </a>
            </Link>
          </div>
          <hr className={styles.icons__divider} />
          <div
            className={`${styles.icons__icon__wrap} ${styles.icons__icon__wrap__hamburguer}`}
          >
            <Hamburguer className={styles.hamburguer__icon} onClick={toggle} />
          </div>
        </div>
      </div>
    </div>
  );
}
