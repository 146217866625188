import { AppProps } from 'next/app';
import React, { useEffect } from 'react';

import { SettingsProvider } from '@/contexts/settings';
import { LayoutProvider } from '@/contexts/layout';
import { ReservationProvider } from '@/contexts/reservation';
import Layout from '@/components/Layout';

import '@/styles/fonts.css';
import '@/styles/globals.css';
import '@/styles/form.css';
import '@/styles/adyen.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../i18n';
import ScreenViewEvent from '@/components/DataLayer/ScreenViewEvent';

function MyApp({ Component, pageProps, router }: AppProps) {
  useEffect(() => {
    console.log('version: 0.1.13');
  }, []);

  return (
    <SettingsProvider>
      <ReservationProvider>
        <LayoutProvider>
          <ScreenViewEvent />
          <Layout page={router.route}>
            <Component {...pageProps} />
          </Layout>
        </LayoutProvider>
      </ReservationProvider>
    </SettingsProvider>
  );
}

export default MyApp;
